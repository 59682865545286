import React from "react";
import { Link } from "gatsby";

import Bio from "../components/Bio";
import "./styles.css";

interface OwnProps {
  location: any;
  title?: string;
}

class Layout extends React.Component<OwnProps> {
  render() {
    const { location, title, children } = this.props;
    // @ts-ignore
    const rootPath = `${__PATH_PREFIX__}/`;
    let header;

    if (location.pathname === rootPath) {
      header = (
        <div
          className="horizontally-centered-container"
          style={{ paddingBottom: "25px" }}
        >
          <h1
            style={{
              marginTop: 0,
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              {title}
            </Link>
          </h1>
          <Bio />
        </div>
      );
    } else {
      header = (
        <h3
          style={{
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      );
    }
    return (
      <div
        className="horizontally-centered-container"
        style={{ paddingTop: "100px", paddingBottom: "100px" }}
      >
        <div className="grain-filter" />
        {header}
        <div className="content">{children}</div>
        <footer style={{ padding: "25px" }}>
          © Rahul Gupta-Iwasaki | Built with{" "}
          <a href="https://gatsbyjs.org">Gatsby</a>
        </footer>
      </div>
    );
  }
}

export default Layout;
