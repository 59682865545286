import React from "react";
import Script from "react-load-script";

import "./Mailchimp.css";

export function Mailchimp() {
  return (
    <div>
      <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css" />
      <div className="card" id="mc_embed_signup">
        <form action="https://raha.us18.list-manage.com/subscribe/post?u=df19cb2bb2149b51990f5aa31&amp;id=4cdce98584" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
        <div id="mc_embed_signup_scroll">
          <h2>Thanks for reading!</h2><h3>Subscribe to hear about new posts.</h3>
          <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
          <div className="mc-field-group">
            <label htmlFor="mce-EMAIL">Email Address  <span className="asterisk">*</span>
            </label>
            <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" />
          </div> <div className="mc-field-group"> <label htmlFor="mce-FNAME">First Name </label>
            <input type="text" name="FNAME" id="mce-FNAME" />
          </div>
          <div className="mc-field-group">
            <label htmlFor="mce-LNAME">Last Name </label>
            <input type="text" name="LNAME" id="mce-LNAME" />
          </div>
          <div id="mce-responses" className="clear">
            <div className="response" id="mce-error-response" style={{display:"none"}}></div>
            <div className="response" id="mce-success-response" style={{display:"none"}}></div>
          </div>    { /* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */ }
          <div style={{position: "absolute", left: "-5000px", ariaHidden:"true"}}><input type="text" name="b_df19cb2bb2149b51990f5aa31_4cdce98584" tabIndex="-1" /></div>
          <div className="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" /></div>
</div>
        </form>
      </div>
      <Script url="http://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
          onLoad={() => {
            (function($) {
              window.fnames = new Array(); 
              window.ftypes = new Array();

              fnames[0]='EMAIL';
              ftypes[0]='email';
              
              fnames[1]='FNAME';
              ftypes[1]='text';

              fnames[2]='LNAME';
              ftypes[2]='text';
            }(jQuery));

            // var $mcj = jQuery.noConflict(true);
          }}
      />
    </div>
  );
}

