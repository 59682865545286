import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

import { Mailchimp } from "./Mailchimp";

import "./styles.css";

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author } = data.site.siteMetadata;
        return (
          <div
            className="horizontally-centered-container"
            style={{ alignContent: "center" }}
          >
            <div
              className="vertically-centered-container"
              style={{ maxWidth: "500px" }}
            >
              <Image
                fixed={data.avatar.childImageSharp.fixed}
                alt={author}
                style={{
                  minWidth: 50,
                  borderRadius: `100%`,
                  zIndex: 2,
                }}
              />
              <p style={{ paddingLeft: "10px" }}>
                A blog by{" "}
                <strong>
                  <a href="https://rahulgi.com">{author}</a>
                </strong>
              </p>
            </div>
          </div>
        );
      }}
    />
  );
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
      }
    }
  }
`;

export default Bio;
